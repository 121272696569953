<template>
<body class="home-page page basicpage light-theme">
    <div id="oc-wrapper">
        <header class="header-v2 header-v2-cn" id="header-v2" style="display: block;">

            <Head></Head>
        </header>

        <section id="oc-container" class="oc-container-v2">
            <div class="root responsivegrid">

                <div class="aem-Grid aem-Grid--12 aem-Grid--default--12 ">

                    <div class="responsivegrid aem-GridColumn aem-GridColumn--default--12">

                        <div class="aem-Grid aem-Grid--12 aem-Grid--default--12 ">

                            <div class="newproductlistbanner parbase aem-GridColumn aem-GridColumn--default--12">

                                <main class="product-main_v2-3 product-main_v2-3-cn">
                                    <section class="product-recommond">
                                        <div class="product-type">

                                            <div class="product-header tab-warp-dom" id="product-header">
                                                <div class="border-box">
                                                    <div class="header-box">
                                                        <p class="header-box-title" style="padding-top:0px">
                                                            <span>产品列表</span>
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="nav-header-box header-box">
                                                    <ul class="nav-header-list">

                                                        <li :class="{'active' : isIndex == i}" :data-type="'all_item'+(i+1)" v-for="(item,i) in type" :key="i" @click="tab(i)">
                                                            {{item.itemText}}
                                                        </li>

                                                        <!-- <li data-type="all_item2">物联网资费</li> -->
                                                    </ul>

                                                </div>
                                            </div>
                                            <div class="swiper-container type-box">
                                                <div class="swiper-wrapper type-list">
                                                    <div :class="isIndex == i ? 'swiper-slide active' : 'swiper-slide hide'" v-for="(item,i) in type" :key="i">
                                                        <template v-for="(d,k) in product.filter(f => f.type == item.itemValue)" :key="k">
                                                            <div class="type-list_item" v-if="d.hot">
                                                                <!-- <a href="https://www.oppo.com/cn/smartphones/series-find-x/find-x6-pro/" class="item_link">

                                                                </a> -->
                                                                <div class="item_link" @click="toLink(d.id)">
                                                                    <picture>
                                                                        <source :srcset="baseUrl + d.mainImgM" media="(max-width: 767px)" type="image/webp">
                                                                        <source :srcset="baseUrl + d.mainImgM" media="(max-width: 767px)">
                                                                        <source :srcset="baseUrl + d.mainImg" type="image/webp">
                                                                        <img :src="baseUrl + d.mainImg" src-webp="true" />
                                                                    </picture>
                                                                </div>
                                                                <div class="description" style="color:#ffffff">
                                                                    <div class="title">{{d.name}}</div>
                                                                    <div class="subtitle">{{d.remark}}</div>
                                                                    <div class="price">

                                                                        <span class="buy-btn btn" style="background-color:; color:;" @click="toLink(d.id)">
                                                                            了解更多
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="newproductlistitem parbase section" v-else>

                                                                <div class="list-item" data-type="all_item1">

                                                                    <div class="item-box">
                                                                        <!-- <router-link :to="'/product-info/'+d.id" class="img-box" style="text-decoration: none;">
                                                                            
                                                                        </router-link> -->
                                                                        <div class="img-box" @click="toLink(d.id)">
                                                                            <div class="img-wrapper active">
                                                                                <!-- <source :srcset="baseUrl + d.imgPathM" media="(max-width: 767px)" type="image/webp">
                                                                                <source :srcset="baseUrl + d.imgPathM" media="(max-width: 767px)">
                                                                                <source :srcset="baseUrl + d.imgPath" type="image/webp"> -->
                                                                                <img class="lazyload img" :src="baseUrl + d.imgPath" data-src-webp="true" />
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <div class="description">

                                                                        <div class="title">{{d.name}}</div>

                                                                        <div class="subtitle">{{d.remark}}</div>
                                                                        <div class="price">

                                                                            <span class="learn-more btn" @click="toLink(d.id)">
                                                                                <!-- <router-link :to="'/product-info/'+d.id" style="text-decoration: none;">
                                                                                    
                                                                                </router-link> -->
                                                                                了解更多
                                                                            </span>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </template>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </main>

                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </section>

        <span>

            <section class="light-theme">
                <footer id="footer">
                    <Foot></Foot>
                </footer>
            </section>
        </span>

        <!-- Load below clientlib only if it is support root -->

    </div>

</body>
</template>

<script>
import {
    productType,
    productData,
    productInfo
} from '@/request/api'
import Head from '../../components/heads.vue'
import Foot from '../../components/footer.vue'
export default {
    components: {
        Head,
        Foot
    },
    data() {
        return {
            type: [],
            product: [],
            isIndex: -1,
            baseUrl: "https://www.inrunonline.com/api/sys/common/static/"
        }
    },
    mounted() {
        this.isIndex = 0;
    },
    created() {
        productType().then((res) => {
                let data = res.result;
                this.type = data;
            }),
            productData().then((res) => {
                this.product = res.result;
            })
    },
    methods: {
        tab(e) {
            this.isIndex = e;

        },
        toLink(id) {
            productInfo({
                id: id
            }).then((res) => {
                let data = res.result;
                if (data && data.details) {
                    this.$router.push({
                        name: 'productInfo',
                        params: {
                            id: id
                        }
                    })

                }
            })
        }
    }
}
</script>

<style>
@media (max-width: 767.98px) {
    /* .product-main_v2-3 .type-list .description .title{
        color:#000
    }
    .product-main_v2-3 .type-list .description .subtitle{color:#000}
    .product-main_v2-3 .type-list .description .price .btn:first-child{
        color:#000
    } */
    .product-recommond .list-item .item-box div.img-box {
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 0;
        width: 100%;
        height: 100%;
    }
}
</style>
